<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="5"
          >
            <v-card>
              <v-card-title class="text-center justify-center py-6">
                <v-list>
                  <v-list-item>
                    <base-img
                      :src="require('@/assets/logo_light.png')"
                      :alt="strings.logo"
                      contain
                      max-width="200"
                      min-height="100"
                      class="logo"
                    />
                  </v-list-item>
                </v-list>
              </v-card-title>

              <v-tabs
                v-model="tab"
              >
                <v-tab
                  key="signin"
                >
                  <v-toolbar-title>{{strings.tabs.signIn}}</v-toolbar-title>
                </v-tab>
                <v-tab
                  key="signup"
                >
                  <v-toolbar-title>{{strings.tabs.signUp}}</v-toolbar-title>
                </v-tab>
              </v-tabs>
              <notifications position="top center">
                <template slot="body" slot-scope="props">
                  <v-alert
                    :type="props.item.type"
                    elevation="2"
                    class="ma-0 mt-10"
                  >
                    {{props.item.text}}
                  </v-alert>
                </template>
              </notifications>
              <v-tabs-items v-model="tab">
                <v-tab-item
                  key="signin"
                >
                  <v-card flat>
                    <v-form
                      ref="signin"
                      @submit="signIn"
                      onSubmit="return false;"
                    >
                      <v-container class="pa-10 pt-5">
                        <v-text-field
                          :label="strings.signIn.email"
                          name="email"
                          type="email"
                          :rules="rules.email"
                          v-model="signin.email"
                        />
                        <v-text-field
                          id="password"
                          :label="strings.signIn.password"
                          name="password"
                          type="password"
                          :rules="rules.password"
                          v-model="signin.password"
                        />
                        <v-btn class="mt-6" color="primary" type="submit" :loading="loading">Login</v-btn>
                      </v-container>
                    </v-form>
                  </v-card>
                </v-tab-item>

                <v-tab-item
                  key="signup"
                >
                  <v-card flat>
                    <v-form
                      ref="signup"
                      @submit="signUp"
                      onSubmit="return false;"
                    >
                      <v-container class="pa-10 pt-5">
                        <v-text-field
                          :label="strings.signUp.name"
                          name="name"
                          type="text"
                          :rules="rules.name"
                          v-model="signup.name"
                        />
                        <v-text-field
                          :label="strings.signUp.surname"
                          name="surname"
                          type="text"
                          :rules="rules.surname"
                          v-model="signup.surname"
                        />
                        <v-text-field
                          :label="strings.signUp.email"
                          name="email"
                          type="email"
                          :rules="rules.email"
                          v-model="signup.email"
                        />
                        <v-text-field
                          :label="strings.signUp.password"
                          name="password"
                          type="password"
                          :rules="rules.password"
                          v-model="signup.password"
                        />
                        <v-text-field
                          :label="strings.signUp.repeatPassword"
                          name="password2"
                          type="password"
                          :rules="rules.password"
                          v-model="signup.password2"
                        />
                        <v-select
                          v-model="signup.language"
                          :items="languages"
                          :label="strings.signUp.language"
                          hide-details=false
                          @change="changeLanguage"
                        ></v-select>
                        
                        <v-btn class="mt-6" color="primary" type="submit" :loading="loading">Sign up</v-btn>
                      </v-container>
                    </v-form>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import View from '@/views/View'
  import i18n from '@/lang/lang'
  import { now } from 'moment'

  export default {
    name: 'Login',

    metaInfo: {
      title: i18n.t('views.login.metaInfo.title'),
    },
    
    extends: View,

    data: () => ({
      loading: false,
      languages: [
        { value: 'ca', text: 'Català' },
        { value: 'es', text: 'Español' },
        { value: 'en', text: 'English' }
      ],
      strings: {
        logo: i18n.t('views.login.logo'),
        welcome: i18n.t('views.login.welcome'),
        tabs: {
          signIn: i18n.t('views.login.tabs.signIn'),
          signUp: i18n.t('views.login.tabs.signUp')
        },
        signIn: {
          email: i18n.t('views.login.signIn.email'),
          password: i18n.t('views.login.signIn.password')
        },
        signUp: {
          name: i18n.t('views.login.signUp.name'),
          surname: i18n.t('views.login.signUp.surname'),
          email: i18n.t('views.login.signUp.email'),
          password: i18n.t('views.login.signUp.password'),
          repeatPassword: i18n.t('views.login.signUp.repeatPassword'),
          language: i18n.t('views.login.signUp.language')
        }
      },
      tab: null,
      rules: {
        name:[
          v => !!v || i18n.t('views.login.validations.mandatoryField'),
        ],
        surname:[
          v => !!v || i18n.t('views.login.validations.mandatoryField'),
        ],
        email:[
          v => !!v || i18n.t('views.login.validations.mandatoryField'),
          v => /.+@.+/.test(v) || i18n.t('views.login.validations.email')
        ],
        password:[
          v => !!v || i18n.t('views.login.validations.mandatoryField'),
          v => (!!v && v.length >= 8 && v.length <= 25) || i18n.t('views.login.validations.password'),
          v => /.*\d.*/.test(v) || i18n.t('views.login.validations.password'),
          v => /.*[A-Z]+.*/.test(v) || i18n.t('views.login.validations.password'),
          v => /.*[a-z]+.*/.test(v) || i18n.t('views.login.validations.password'),
        ],
      },
      signin: {
        email: 'john.doe@tactilens.com',
        password: 'JohnDoe1234!'
      },
      signup: {
        name: 'John',
        surname: 'Doe',
        email: 'john.doe.'+now()+'@tactilens.com',
        language: i18n.locale,
        password: 'JohnDoe1234!',
        password2: 'JohnDoe1234!'
      }
    }),
    
    methods:{
      signIn() {
        if (!this.$refs.signin.validate()) return;

        this.loading = true;
        this.$auth.signIn(this.signin.email, this.signin.password)
        .then(() => {
          this.$router.push({name: "app"});
        })
        .catch(error => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
      },
      signUp() {
        if (!this.$refs.signup.validate()) return;

        this.loading = true;
        return new Promise((resolve, reject) => {
          this.$auth.signUp(this.signup)
          .then(() => {
            this.$router.push({name: "app"});
          })
          .catch(error => {
            this.showError(error);
            this.loading = false;
            reject(error);
          });
        });
      },
      changeLanguage(key) {
        this.signup.language = key;
      },
      showError(error) {
        let errorMessage = i18n.t('exceptions.default');
        if( error.response ){
            errorMessage = i18n.t('exceptions.' + error.response.data.error);
        }
        this.$notify({
          type: "error",
          duration: 5000,
          text: errorMessage,
          ignoreDuplicates: true
        });
      }
    }
  }
</script>
<style lang="scss" scoped>
  .vue-notification-group {
    position: relative !important;
  }
  .logo {
    position:relative;
    display:block;
    text-align:center;
    margin: auto;
  }
</style>